import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  startValue!: number;
  endValue!: number;
  durationValue!: number;

  static values = {
    start: Number,
    end: Number,
    duration: Number,
  };

  connect(): void {
    this.animate();
  }

  animate(): void {
    let startTimestamp: number | null = null;

    const step = (timestamp: number): void => {
      if (!startTimestamp) startTimestamp = timestamp;

      const elapsed: number = timestamp - startTimestamp;
      const progress: number = Math.min(
        elapsed / Math.max(this.durationValue, 1),
        1
      );

      this.element.innerHTML = Math.floor(
        progress * (this.endValue - this.startValue) + this.startValue
      ).toString();

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }
}
