import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect(): void {
    document.addEventListener('click', (event: Event) => {
      const link = event.target as HTMLAnchorElement | null;
      const destination = link?.href && new URL(link.href);

      if (
        !destination ||
        !destination.hash ||
        destination.pathname !== document.location.pathname
      )
        return;
      event.preventDefault();
      document.querySelector(destination.hash)?.scrollIntoView({
        behavior: 'smooth',
      });
    });
  }
}
