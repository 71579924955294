import { Controller } from '@hotwired/stimulus';
import type { PurchaseUnit } from '@paypal/paypal-js';
import { loadScript } from '@paypal/paypal-js';

// Connects to data-controller="paypal"
export default class extends Controller {
  declare readonly amountValue: string;
  declare readonly descriptionValue: string;
  declare readonly idValue: string;
  declare readonly clientIdValue: string;
  declare readonly inputTarget: HTMLInputElement;

  static targets = ['input'];

  static values = {
    amount: String,
    description: String,
    id: String,
    clientId: String,
  };

  async connect(): Promise<void> {
    const paypal = await loadScript({
      'client-id': this.clientIdValue,
      'disable-funding': 'sofort,sepa',
      currency: 'EUR',
    });
    if (!paypal?.Buttons) return;

    const purchase_units: PurchaseUnit[] = [
      {
        custom_id: this.idValue,
        amount: {
          value: this.amountValue,
          currency_code: 'EUR',
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: this.amountValue,
            },
          },
        },
        items: [
          {
            name: this.descriptionValue,
            unit_amount: {
              currency_code: 'EUR',
              value: this.amountValue,
            },
            quantity: '1',
          },
        ],
      },
    ];

    paypal
      .Buttons({
        createOrder: (_, actions) => {
          return actions.order.create({
            purchase_units,
            application_context: {
              brand_name: 'Anymator',
            },
          });
        },
        onApprove: async (data) => {
          this.inputTarget.value = data.orderID;
          this.inputTarget.form?.requestSubmit();
        },
      })
      .render(this.element as HTMLElement);
  }
}
