import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  urlValue!: string;

  static values = {
    url: String,
  };

  async share(): Promise<void> {
    try {
      this.element.classList.add('loading');
      const res = await fetch(this.urlValue);
      const blob = await res.blob();
      const filename =
        res.headers.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1] ??
        this.urlValue.split('/').pop() ??
        'file.mp4';
      const file = new File([blob], filename, { type: blob.type });
      await navigator.share({ files: [file] });
      // catch the abort error
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        // dismiss the error
      } else {
        throw error;
      }
    } finally {
      this.element.classList.remove('loading');
    }
  }
}
