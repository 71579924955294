import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="autogrow"
export default class extends Controller {
  input(e: { target: { value: string | undefined } }): void {
    const input = this.element.querySelector(
      '.input'
    ) as HTMLInputElement | null;
    if (input) {
      input.dataset.replicatedValue = e.target.value;
    }
  }
}
