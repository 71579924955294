import { ui } from '@nerdgeschoss/shimmer';

export async function enableSendInBlue(): Promise<void> {
  await ui.consent.consentFor('targeting');
  const scriptTag = document.createElement('script');
  scriptTag.textContent = `
    (function(d, w, c) {
      w.SibConversationsID = '635161674f92c8670a11537c';
      w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
      };
      var s = d.createElement('script');
      s.async = true;
      s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
      if (d.head) d.head.appendChild(s);
  })(document, window, 'SibConversations');`;
  document.head.appendChild(scriptTag);
}
