import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initializeOnConnectValue!: boolean;

  static values = { initializeOnConnect: { type: Boolean, default: false } };

  connect(): void {
    if (this.initializeOnConnectValue) {
      setTimeout(() => this.on(), 0);
    }
  }

  on(): void {
    this.element.classList.add('active');
  }
  off(): void {
    this.element.classList.remove('active');
  }
}
