import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = ['picker', 'image'];
  private pickerTargets!: HTMLElement[];
  private imageTarget!: HTMLElement;

  scrollTo(): void {
    this.imageTarget.scrollIntoView({
      inline: 'center',
      behavior: 'smooth',
    });
  }

  wigglePickers(): void {
    this.scrollTo();
    setTimeout(
      () =>
        this.pickerTargets.forEach((picker) => {
          picker.classList.add('wiggle');
          setTimeout(() => picker.classList.remove('wiggle'), 500);
        }),
      500
    );
  }
}
