import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['video'];
  declare videoTarget: HTMLVideoElement;

  play(): void {
    try {
      this.videoTarget.controls = true;
      this.videoTarget.play();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
